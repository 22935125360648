<template>
    <div class="py-32 text-white hero pt-32 ">
        <div class="flex flex-col lg:flex-row justify-between items-center gap-4 max-w-7xl mx-auto px-4">
            <div class="lg:text-left text-center">
                <h1 class="font-bold md:text-4xl text-3xl">Gen AI for the Enterprise</h1>
                <span class="md:text-2xl text-xl mt-3">Live data pipelines for AI applications.</span>
                <span class="block text-gray-300 mt-3">Quickly put in production AI applications which offer
                    high-accuracy RAG at scale using the most up-to-date knowledge available in your data
                    sources.</span>
                <div class="flex gap-4 items-center justify-center lg:justify-start mt-8">
                    <Calendly name="requestdemo" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                        class="button button--secondary flex gap-3 text-xl items-center justify-center self-start">
                        <Icon name="heroicons:chat-bubble-left-right-solid" />Let's talk
                    </Calendly>
                    <NuxtLink href="/developers/templates?tab=ai=pipelines"
                        class="flex gap-2 items-center font-bold text-xl bg-transparent block max-w-max hover:bg-[#dc280b] px-6 py-2 rounded-lg border-2 border-[#dc280b] !transition">
                        Try out RAG templates
                    </NuxtLink>
                </div>
            </div>
            <div class="mt-14 lg:mt-0">
                <div class="text-lg">
                    <ul>
                        <li class="flex gap-2 items-center justify-center lg:justify-start">
                            <Icon name="heroicons:academic-cap-solid" /> High accuracy knowledge retrieval
                        </li>
                        <li class="flex gap-2 items-center justify-center lg:justify-start">
                            <Icon name="heroicons:arrow-path-20-solid" />Live synchronization with data sources
                        </li>
                        <li class="flex gap-2 items-center justify-center lg:justify-start">
                            <Icon name="heroicons:document-duplicate-20-solid" />Unstructured document support (PDF,
                            DOC,...)
                        </li>
                        <li class="text-center lg:text-left">
                            <span class="inline">
                                <Icon name="heroicons:rocket-launch-20-solid" class="inline align-middle mr-2" />Fast
                                built-in vector
                                indexing up to
                                <span class="font-bold">millions of documents</span>
                            </span>*

                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="max-w-7xl mx-auto mt-8">
            <span class="lg:text-right w-full block text-primary-200 text-sm text-center">* Contact us for help with
                setting up
                enterprise use
                cases at
                scale.</span>
        </div>
    </div>
    <!-- <div class="bg-gray-100">
        <div class="max-w-7xl mx-auto py-16 lg:px-0 px-16">
            <h2 class="text-3xl font-bold mb-14">Solutions</h2>
            <div>
                <ul
                    class="lg:grid-cols-[repeat(auto-fill,minmax(20rem,1fr))] flex flex-wrap justify-center grid gap-4 text-pretty px-4">
                    <li class="text-center flex flex-col p-4 border-2 border-gray-200 bg-gray-50">
                        <div>
                            <div
                                class="p-4 max-w-max mx-auto bg-white rounded-full flex items-center justify-center shadow-[inset_0_0_0_1px_hsla(0,0%,0%,0.1)]">
                                <Icon name="heroicons:arrow-path-16-solid" class="h-10 w-10 text-primary-500" />
                            </div>
                            <span class="font-bold mt-4 mb-2 block text-lg">Realtime Document Indexing</span>
                        </div>
                        <div class="flex items-center justify-center h-full mb-8">
                            <ul>
                                <li>
                                    <span class="align-middle ml-1">Real-time
                                        document indexing from
                                        Microsoft 365
                                        SharePoint</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1">Real-time
                                        document indexing from
                                        Google Drive</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1">Similarity
                                        search by user query</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1">Filtering by
                                        the metadata
                                        according to the condition given in <a href="https://jmespath.org/"
                                            class="text-primary-500 hover:underline">JMESPath</a>
                                        format</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1">Basic stats on
                                        the indexer's
                                        health</span>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-auto">
                            <NuxtLink to="/solutions/rag-pipelines"
                                class="button button--secondary flex flex-wrap gap-3 items-center justify-center self-start">
                                Learn more</NuxtLink>
                        </div>
                    </li>
                    <li class="text-center flex flex-col gap-4 p-4 border-2 border-gray-200 bg-gray-50">
                        <div>
                            <div
                                class="p-4 max-w-max mx-auto bg-white rounded-full flex items-center justify-center shadow-[inset_0_0_0_1px_hsla(0,0%,0%,0.1)]">
                                <Icon name="prime:microchip-ai" class="h-10 w-10 text-primary-500" />
                            </div>
                            <span class="font-bold mt-4 mb-2 block text-lg text-pretty">Retrieval-Augmented Generation
                                for
                                document-based AI magic</span>
                        </div>

                        <div class="flex items-center justify-center h-full mb-8">
                            <ul>
                                <li>
                                    <span class="align-middle ml-1"><span class="font-medium">Accurate & Insightful
                                            Answers</span> sourced directly from
                                        documents.</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1"><span class="font-medium">Effortless
                                            Maintenance</span>: No separate data
                                        preprocessing, no vector database. Pathway
                                        automatically keeps itself up-to-date.</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1"><span class="font-medium">Highly
                                            Customizable</span>: Adapt the pipeline to your
                                        needs, including prompts, search
                                        queries, and more.</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1"><span class="font-medium">Seamless
                                            Integration</span>: Works with various document
                                        sources and integrates with your
                                        existing workflows.</span>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-auto">
                            <NuxtLink to="/solutions/ai-contract-management"
                                class="button button--secondary flex flex-wrap  gap-3 items-center justify-center self-start">
                                Contract Management</NuxtLink>
                            <NuxtLink to="/solutions/slides-ai-search"
                                class="button button--secondary flex flex-wrap mt-2  gap-3 items-center justify-center self-start">
                                Accurate Slide Search</NuxtLink>
                        </div>
                    </li>
                    <li class="text-center flex flex-col p-4 border-2 border-gray-200 bg-gray-50">
                        <div>
                            <div
                                class="p-4 max-w-max mx-auto bg-white rounded-full flex items-center justify-center shadow-[inset_0_0_0_1px_hsla(0,0%,0%,0.1)]">
                                <Icon name="heroicons:rectangle-stack-solid" class="h-10 w-10 text-primary-500" />
                            </div>
                            <span class="font-bold mt-4 mb-2 block text-lg">Unstructured To Structured Data</span>
                        </div>
                        <div class="flex items-center justify-center h-full mb-8">
                            <ul>
                                <li>

                                    <span class="align-middle ml-1">Powerful data Transformation for all your ML use
                                        cases.</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1">Get instant insights from streams of unstructured
                                        data on-the-fly.</span>
                                </li>
                                <li class="mt-3">
                                    <span class="align-middle ml-1">Connect live sources with documents, e-mails, social
                                        media and messages, audio and
                                        video transcripts, geospatial data.</span>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-auto">
                            <NuxtLink to="/solutions/etl-unstructured-data"
                                class="button button--secondary flex gap-3 items-center justify-center self-start">
                                Learn more</NuxtLink>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <hr />
    <div class="bg-[#F8FAFC] py-16 px-8">
        <div class="max-w-7xl mx-auto">
            <h2 class="text-3xl font-bold">Use cases & Industries</h2>
            <ClickSliderText path="llm-app" />
            <div class="py-14 pt-28 max-w-7xl mx-auto">
                <div class="flex flex-col lg:flex-row gap-4  text-pretty text-lg leading-6">
                    <div
                        class="flex justify-between w-full gap-4 items-center border border-[#DEDFFC] p-6 py-8 bg-white pw-text-black rounded-lg">
                        <span>Fully customizable in Python</span>
                        <div class="relative z-10">
                            <Icon name="devicon:python" class="w-14 h-14 text-[#181CF0]" />
                        </div>
                    </div>
                    <div
                        class="flex justify-between w-full gap-4 items-center border border-[#DEDFFC] p-6 py-8 bg-white pw-text-black rounded-lg">
                        <span>Deploys with Kubernetes</span>
                        <div class="relative z-10">
                            <Icon name="devicon:kubernetes" class="w-14 h-14 text-[#181CF0]" />
                        </div>
                    </div>
                    <div
                        class="flex justify-between w-full gap-4 items-center border border-[#DEDFFC] p-6 py-8 bg-white pw-text-black rounded-lg">
                        <span>Runs on cloud, runs in a data center, runs in a Faraday cage</span>
                        <div class="relative z-10">
                            <Icon name="heroicons:cloud-20-solid" class="w-14 h-14 text-[#181CF0]" />
                        </div>
                    </div>
                </div>
                <div class="max-w-7xl mx-auto">
                    <div class="flex gap-4 items-center justify-center mt-8 lg:mt-0 px-4">
                        <a href="https://github.com/pathwaycom/llm-app/tree/main/examples/pipelines/demo-document-indexing"
                            class="flex gap-2 items-center font-semibold  mt-8 bg-transparent block max-w-max hover:bg-[#dc280b] hover:text-white px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                            <Icon name="mdi:github" />
                            <Icon name="mdi:docker" /> See source code and run in Docker
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-[#19242F]">
        <div class="max-w-7xl mx-auto md:p-8 md:py-16  px-4 py-8 text-white">
            <span class="text-3xl font-semibold">Backed by Enterprise Security & Authentication</span>
            <div class="flex flex-col md:flex-row gap-8 justify-between items-center">
                <div class="md:w-[75%]">
                    <ul class="flex flex-col gap-4 mt-8">
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Host on your
                                cloud
                                or
                                on-premise</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Secure by
                                Design</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Granular
                                Access
                                Management</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" />
                            <span>Compliance-Ready</span>
                        </li>
                    </ul>
                </div>
                <div class="flex items-center justify-center h-full">
                    <Calendly name="GetStarted" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                        class="flex gap-2 items-center text-white font-bold whitespace-nowrap text-xl hover:!bg-transparent block w-full md:max-w-max !bg-primary-500 px-6 py-3 rounded-lg border-2 border-primary-500 !transition">
                        Let's talk
                        <Icon name="heroicons:chat-bubble-left-right-20-solid" />
                    </Calendly>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.hero {
    background: #141d2d;
    background: linear-gradient(90deg, #141d2d 42%, #152033 50%, #2729b0);
}
</style>
