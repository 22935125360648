<template>
    <div class="py-20 text-white hero pt-32">
        <div class="flex flex-col lg:flex-row justify-between items-center gap-4 max-w-7xl mx-auto px-4">
            <div class="lg:text-left text-center">
                <h1 class="font-bold md:text-3xl text-2xl">RAG pipelines powered by the freshest knowledge</h1>
                <span class="md:text-2xl text-xl">For your documents & live data</span>
                <div class="lg:my-14 my-8">
                    <img src="/cloud/flow-rag.svg" alt="Pathway RAG - indexer flow" />
                </div>
            </div>
            <div>
                <div class="mt-8 text-lg">
                    <div class="flex flex-col lg:text-left text-center">
                        <span class="font-bold">Spin up your all-inclusive RAG pipelines in minutes.</span>
                        <span>One containerized service, no infrastructure dependencies.</span>
                    </div>
                    <ul class="mt-8 ">
                        <li class="flex gap-2 items-center">
                            <Icon name="heroicons:academic-cap-solid" /> High accuracy knowledge retrieval
                        </li>
                        <li class="flex gap-2 items-center">
                            <Icon name="heroicons:arrow-path-20-solid" />Live synchronization with data sources
                        </li>
                        <li class="flex gap-2 items-center">
                            <Icon name="heroicons:document-duplicate-20-solid" />Unstructured document support (PDF,
                            DOC,...)
                        </li>
                        <li>
                            <span class="inline ">
                                <Icon name="heroicons:rocket-launch-20-solid" class="inline align-middle mr-2" />Fast
                                built-in vector
                                indexing up to
                                <span class="font-bold">millions of documents</span>
                            </span>*

                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="max-w-7xl mx-auto">
            <div
                class="flex md:flex-row flex-col gap-4 items-center lg:items-start justify-center lg:justify-start mt-8 lg:mt-0 px-4">
                <a href="/pricing?tab=ai-pipelines"
                    class="font-bold text-xl mt-8 hover:bg-transparent max-w-max bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] transition">
                    Get your hosted AI pipeline
                    <Icon name="heroicons:chat-bubble-left-right-20-solid" class="inline ml-2 align-middle" />
                </a>
                <a href="#try-it-out"
                    class="flex gap-2 items-center font-bold text-xl mt-8 bg-transparent block max-w-max hover:bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                    Try out RAG demo
                </a>
            </div>
        </div>
        <div class="max-w-7xl mx-auto mt-8 lg:mt-0">
            <span class="lg:text-right w-full block text-primary-200 text-sm text-center">* Contact us for help with
                setting up
                enterprise use
                cases at
                scale.</span>
        </div>
    </div>
    <div class="bg-[#F8FAFC]">
        <div class="p-14 max-w-7xl mx-auto">
            <div class="flex flex-col lg:flex-row gap-4  text-pretty text-lg leading-6">
                <div
                    class="flex justify-between w-full gap-4 items-center border border-[#DEDFFC] p-6 py-8 bg-white pw-text-black rounded-lg">
                    <span>Fully customizable in Python</span>
                    <div class="relative z-10">
                        <Icon name="devicon:python" class="w-14 h-14 text-[#181CF0]" />
                    </div>
                </div>
                <div
                    class="flex justify-between w-full gap-4 items-center border border-[#DEDFFC] p-6 py-8 bg-white pw-text-black rounded-lg">
                    <span>Deploys with Kubernetes</span>
                    <div class="relative z-10">
                        <Icon name="devicon:kubernetes" class="w-14 h-14 text-[#181CF0]" />
                    </div>
                </div>
                <div
                    class="flex justify-between w-full gap-4 items-center border border-[#DEDFFC] p-6 py-8 bg-white pw-text-black rounded-lg">
                    <span>Runs on cloud, runs in a data center, runs in a Faraday cage</span>
                    <div class="relative z-10">
                        <Icon name="heroicons:cloud-20-solid" class="w-14 h-14 text-[#181CF0]" />
                    </div>
                </div>
            </div>
            <div class="max-w-7xl mx-auto">
                <div class="flex gap-4 items-center justify-center mt-8 lg:mt-0 px-4">
                    <a href="https://github.com/pathwaycom/llm-app/tree/main/examples/pipelines/demo-document-indexing"
                        class="flex gap-2 items-center font-semibold  mt-8 bg-transparent block max-w-max hover:bg-[#dc280b] hover:text-white px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                        <Icon name="mdi:github" />
                        <Icon name="mdi:docker" /> See source code and run in Docker
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="p-4 max-w-7xl mx-auto px-4 py-24 ">
        <div class="flex md:flex-row flex-col gap-4 items-center justify-center">
            <div class="max-w-lg text-center text-lg">
                Pathway’s hosted pipelines integrated seamlessly with our SharePoint environment and empowered three of
                our
                teams to rapidly independently build Gen AI apps.
                <br><br>
                <span class="text-sm">Ari Bajo Rouvinen - Data Engineer</span>
            </div>
        </div>
    </div>
    <div class="bg-[#19242F]">
        <div class="max-w-7xl mx-auto md:p-8 md:py-16  px-4 py-8 text-white">
            <span class="text-3xl font-semibold">Backed by Enterprise Security & Authentication</span>
            <div class="flex flex-col md:flex-row gap-8 justify-between items-center">
                <div class="md:w-[75%]">
                    <ul class="flex flex-col gap-4 mt-8">
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Host on your
                                cloud
                                or
                                on-premise</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Secure by
                                Design</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Granular
                                Access
                                Management</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" />
                            <span>Compliance-Ready</span>
                        </li>
                    </ul>
                </div>
                <div class="flex items-center justify-center h-full">
                    <Calendly name="GetStarted" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                        class="flex gap-2 items-center text-white font-bold whitespace-nowrap text-xl hover:!bg-transparent block w-full md:max-w-max !bg-primary-500 px-6 py-3 rounded-lg border-2 border-primary-500 !transition">
                        Let's talk
                        <Icon name="heroicons:chat-bubble-left-right-20-solid" />
                    </Calendly>
                </div>
            </div>

        </div>
    </div>
    <hr />
    <div class=" bg-[#F8FAFC] utabs">
        <div class="max-w-7xl mx-auto md:p-14 px-4 py-14 text-[#232a34]">
            <!-- <div id="swagger-pathway-api"></div> -->
            <h2 class="text-3xl font-bold" id="try-it-out">Try it out</h2>
            <h3 class="text-2xl font-medium">Interact with demo pipelines</h3>

            <div id="docindex">
                <CloudDocIndex />
            </div>
        </div>
    </div>

</template>

<style scoped>
.hero {
    background: #141d2d;
    background: linear-gradient(90deg, #141d2d 42%, #152033 50%, #2729b0);
}
</style>
