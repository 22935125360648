<template>
    <section class="py-14 lg:py-32 px-4 bg-[#19242F] content-grid--full">
        <div class="mx-auto max-w-7xl text-[#DDDDE1]">
            <div class="text-center">
                <!-- <h2 class="uppercase tracking-widest text-xl">Some Heading</h2> -->
                <span class="md:text-5xl text-4xl mt-4 block font-light">Pathway for Enterprise brings you</span>
            </div>
            <div class="grid lg:grid-cols-3 grid-cols-1 lg:mx-16 justify-center gap-12 mt-24">
                <NuxtLink to="/solutions/logistics"
                    class="flex flex-col justify-center items-center bg-[#3535EE] hover:bg-primary-500 transition text-[#EBEBED] p-8 px-14 rounded-se-3xl rounded-es-3xl text-center">
                    <span class="text-2xl">Logistics</span>
                    <p class="mt-12 mb-8">Postal Services, 3PL, Container Shipping</p>
                    <img src="/assets/landing/icon-truck.svg" alt="" class="mt-auto" />
                    <span class="flex items-center gap-2 mt-8">Read More
                        <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4" />
                    </span>
                </NuxtLink>
                <NuxtLink to="/solutions/enterprise-generative-ai"
                    class="flex flex-col justify-center items-center bg-[#3535EE] hover:bg-primary-500 transition text-[#EBEBED] p-8 px-14 rounded-se-3xl rounded-es-3xl text-center">
                    <span class="text-2xl">Document Search</span>
                    <p class="mt-12 mb-8">Live data pipelines for AI apps, LLM-powered analytics</p>
                    <img src="/assets/landing/icon-phone.svg" alt="" class="mt-auto" />
                    <span class="flex items-center gap-2 justify-end mt-8">Read More
                        <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4" />
                    </span>
                </NuxtLink>
                <NuxtLink to="/solutions/ai-enabled-vehicles-n-esports"
                    class="flex flex-col justify-center items-center bg-[#3535EE] hover:bg-primary-500 transition text-[#EBEBED] p-8 px-14 rounded-se-3xl rounded-es-3xl text-center">
                    <span class="text-2xl">AI-enabled vehicles & eSports</span>
                    <p class="mt-4 mb-8">Telemetry tracking, Augmented reality, Realtime monitoring</p>
                    <img src="/assets/landing/icon-car.svg" alt="" class="mt-auto" />
                    <span class="flex items-center gap-2 mt-8">Read More
                        <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4" />
                    </span>
                </NuxtLink>
            </div>
            <div class="lg:mx-16 mt-24 flex lg:flex-row flex-col justify-between lg:items-end items-start">
                <div>
                    <span class="text-[#DDDDE1] font-medium text-2xl text-left lg:text-left block mb-8">What you get
                        with
                        our <span class="text-[#F66565]">Pathway for Enterprise</span></span>
                    <ul class="flex  flex-col gap-4">
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" />
                            Horizontal
                            Scalability
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> Machine Learning
                            Toolboxes</li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> Support with SLA
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> Secure by design
                        </li>
                    </ul>

                </div>
                <div
                    class="flex lg:flex-row flex-col lg:mt-0 mt-16 items-center justify-center lg:justify-start w-full lg:w-auto gap-4">
                    <PathwayButton type="light" href="/pricing">See
                        Full
                        Features
                        List</PathwayButton>
                    <Calendly name="GetStarted" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                        class="flex items-center w-max h-full pw-button--primary-white font-medium gap-2 p-4 px-10 text-black rounded-xl  transition">
                        Let's talk
                        <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4 text-primary-700 button-arrow" />
                    </Calendly>
                </div>


            </div>
            <!-- <GartnerSection /> -->
        </div>
    </section>
</template>

<style scoped>
.text-balance {
    text-wrap: balance;
}

.linebottom {
    position: relative;
}

.linebottom::after {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 3rem);
    border-right: 2px dashed #DDDDE1;
    left: 50%;
    margin-top: 0.5rem;
    transform: translateX(-50%);
}
</style>
