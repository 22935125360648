<template>
  <Banner href="/news/as-cohere-and-writer-mine-the-live-ai-arena-pathway-joins-the-pack-with-a-10m-round"
    v-if="path == '/'">
    <span class="font-bold">In the Spotlight:</span> Pathway secures $10 Million led by TQ Ventures to advance the
    development of Live AI. Read it on TechCrunch.
  </Banner>
  <header
    class="sticky top-0 z-30 w-full h-header bg-white backdrop-filter backdrop-blur-lg bg-opacity-90 px-2 nav-header">
    <div class="flex items-center h-full justify-between max-w-7xl mx-auto">
      <NavbarLogo />
      <nav v-if="isDevelopers" class="mx-8 hidden md:block">
        <ul class="hidden md:block">
          <li class="px-1 ">
            <nuxt-link to="/" class="hover:underline font-medium flex gap-2 items-center">
              <Icon name="heroicons-outline:home" /> Homepage
            </nuxt-link>
          </li>
        </ul>
      </nav>
      <NavbarCenter v-else />
      <NavbarDialog />
      <div class="flex gap-4 items-center" :class="{ 'md:w-full nav--dev': isDevelopers }">
        <AppSearch :class="{ 'w-full': isDevelopers }" v-if="isDevelopers" />
        <NavbarSocials />
        <UserNavigation />
      </div>
    </div>
  </header>
</template>

<script setup>
const { page } = useContent()
const path = computed(() => {
  if (page.value?._path) {
    return page.value?._path
  } else if (process.client) {
    return window.location.pathname
  }
})
const isDevelopers = computed(() => path.value?.startsWith('/developers'))
</script>
