<template>
    <div class="h-full flex items-center hidden md:flex">
        <!-- <Modal name="Login" 
            class="flex items-center gap-2 whitespace-nowrap shadow-sm p-2 rounded-md text-sm border border-gray-300 transition hover:border-gray-500 font-medium hover:bg-gray-100">
            Start for free</Modal> -->

        <UPopover v-if="user?.provider" :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }">
            <button type="button"
                class="flex items-center gap-2 shrink-0 p-1 rounded-lg border border-gray-300 hover:bg-gray-100 hover:border-gray-500 transition"
                @click="dropdownOpen">
                <img :src="user?.picture" :alt="`${user.name} profile picture`"
                    class="w-8 h-8 rounded-full object-cover" />
            </button>
            <template #panel>
                <ul class="bg-white p-1 flex flex-col gap-1">
                    <div class="text-sm text-left p-2">
                        <span class="font-medium block">{{ user?.nickname || user?.name }}</span>
                        <span class="text-xs block">{{ user.email }}</span>
                    </div>
                    <li>
                        <NuxtLink to="/user/license" class="flex gap-2 items-center p-2 hover:bg-gray-100 w-full">
                            <Icon name="heroicons:identification-20-solid" />
                            License
                        </NuxtLink>
                    </li>
                    <li>
                        <button type="button" class="flex gap-2 items-center p-2 hover:bg-gray-100 w-full"
                            @click="logout">
                            <Icon name="heroicons:power-16-solid" /> Logout
                        </button>
                    </li>
                </ul>
            </template>
        </UPopover>


    </div>
</template>
<script setup>
const { user, logout } = await useAuth()
</script>
