<template>
    <div class="flex mt-16 justify-center gap-8 flex-wrap">
        <div class="rounded-lg shadow-xl flex p-4 items-center justify-center md:w-44 w-full max-w-44">
            <img src="/assets/pictures/tq-ventures-logo.jpg" alt="TQ Ventures" />
        </div>
        <div class="rounded-lg shadow-xl flex p-4 items-center justify-center md:w-44 w-full max-w-44">
            <img src="/assets/pictures/Kadmos-Capital.webp" alt="Kadmos" />
        </div>
        <div class="rounded-lg shadow-xl flex p-4 items-center justify-center md:w-44 w-full max-w-44">
            <img src="/assets/pictures/ID4-Ventures.svg" alt="Id4" />
        </div>
        <div class="rounded-lg shadow-xl flex p-4 items-center justify-center md:w-44 w-full max-w-44">
            <img src="/assets/pictures/Inovo.png" alt="Inovo" />
        </div>
        <div class="rounded-lg shadow-xl flex p-4 items-center justify-center md:w-44 w-full max-w-44">
            <img src="/assets/pictures/Market-One-Capital.png" alt="MOC" />
        </div>

    </div>
</template>
